<template>
  <custom-table
    :columns="columns"
    :view-modes="viewModes"
    :default-view-mode="viewMode"
    :custom-view="customView"
    api-url="/goods-receipt-history"
    api-download-url="/goods-receipt-history-export"
    download-file-name="nhap-kho.xlsx"
  ></custom-table>
</template>

<script>
import {
  RECEIPT_DETAIL_STATUS_OPTIONS,
  RECEIPT_STATUS_OPTIONS,
  RECEIPT_VIEW_MODES,
  CUSTOM_VIEW_WAREHOUSE_RECEIPT,
} from "@/libs/const";

export default {
  name: "History",
  components: {},
  data: () => ({
    viewMode: "view0",
    viewModes: [...RECEIPT_VIEW_MODES],
    customView: CUSTOM_VIEW_WAREHOUSE_RECEIPT,
    detailStatusOptions: [...RECEIPT_DETAIL_STATUS_OPTIONS],
    statusOptions: [...RECEIPT_STATUS_OPTIONS],
    columns: [],
  }),
  created() {
    const columns = [
      {
        type: "select-filter",
        label: this.$t("labels.wp_source_type"),
        placeholder: this.$t("labels.wp_source_type"),
        name: "source_type",
        hasSort: false,
        sortName: "source_type",
        key: "source_name",
        options: [
          {
            text: this.$t("labels.warehouse_1"),
            value: "warehouse",
          },
          {
            text: this.$t("labels.pos"),
            value: "pos",
          },
        ],
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.wp_source_code"),
        placeholder: this.$t("labels.wp_source_code"),
        name: "source_code",
        hasSort: false,
        sortName: "source_code",
        key: "source_code",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time"),
        placeholder: this.$t("labels.create_time"),
        name: "create_time",
        hasSort: false,
        sortName: "create_time",
        key: "create_time",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.group_tracking"),
        placeholder: this.$t("labels.group_tracking"),
        name: "goods_receipt_group_tracking",
        hasSort: false,
        sortName: "goods_receipt_group_tracking",
        key: "goods_receipt_group_tracking",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.session_code"),
        placeholder: this.$t("labels.session_code"),
        name: "goods_receipt_tracking",
        hasSort: false,
        sortName: "goods_receipt_tracking",
        key: "goods_receipt_tracking",
        required: true,
        defaultValue: this.getUrlParameter("goods_receipt_tracking"),
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        showModes: ["view1"],
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.receipt_time"),
        placeholder: this.$t("labels.receipt_time"),
        name: "receipt_time",
        hasSort: false,
        sortName: "receipt_time",
        key: "receipt_time",
        showModes: ["view1"],
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status_1"),
        placeholder: this.$t("labels.status_1"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "state",
        options: this.statusOptions,
        showModes: ["view0"],
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status_1"),
        placeholder: this.$t("labels.status_1"),
        name: "detail_status",
        hasSort: false,
        sortName: "detail_status",
        key: "detail_status_txt",
        options: this.detailStatusOptions,
        showModes: ["view1"],
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.first_receipt"),
        placeholder: this.$t("labels.first_receipt"),
        name: "first_handle_time",
        hasSort: false,
        sortName: "first_handle_time",
        key: "first_handle_time",
        showModes: ["view0"],
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.last_receipt"),
        placeholder: this.$t("labels.last_receipt"),
        name: "last_handle_time",
        hasSort: false,
        sortName: "last_handle_time",
        key: "last_handle_time",
        showModes: ["view0"],
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: false,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
        component: "GoodsCode",
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_name"),
        placeholder: this.$t("labels.goods_name"),
        name: "goods_name",
        hasSort: false,
        sortName: "goods_name",
        key: "goods_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "goods_description",
        hasSort: false,
        sortName: "goods_description",
        key: "goods_description",
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "goods_size",
        hasSort: false,
        sortName: "goods_size",
        key: "goods_size",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.request_quantity"),
        placeholder: this.$t("labels.request_quantity"),
        name: "request_quantity",
        hasSort: false,
        sortName: "request_quantity",
        key: "request_quantity",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipt_quantity"),
        placeholder: this.$t("labels.receipt_quantity"),
        name: "receipted_quantity",
        hasSort: false,
        sortName: "receipted_quantity",
        key: "receipted_quantity",
        showModes: ["view0"],
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipt_quantity"),
        placeholder: this.$t("labels.receipt_quantity"),
        name: "history_quantity",
        hasSort: false,
        sortName: "history_quantity",
        key: "history_quantity",
        showModes: ["view1"],
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.sub_quantity"),
        placeholder: this.$t("labels.sub_quantity"),
        name: "sub_quantity",
        hasSort: false,
        sortName: "sub_quantity",
        key: "sub_quantity",
        showModes: ["view0"],
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.employee_create"),
        placeholder: this.$t("labels.employee_create"),
        name: "id_identity_create",
        hasSort: false,
        sortName: "id_identity_create",
        key: "identity_create_name",
      },
      {
        type: "select-supplier",
        label: this.$t("labels.supplier"),
        placeholder: this.$t("labels.supplier"),
        name: "id_supplier",
        hasSort: false,
        sortName: "supplier_name",
        key: "supplier_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        name: "note",
        hasSort: false,
        sortName: "note",
        key: "note",
      },
    ];
    this.columns = [...columns];
  },
};
</script>

<style scoped></style>
